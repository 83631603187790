import { mapHelper } from "@/utils/common.js";
const isOPen = [
  {
    value: false,
    label: "关闭",
  },
  {
    value: true,
    label: "开启",
  },
];
const { map: isOPenMap, setOps: isOPenOps } = mapHelper.setMap(isOPen);
// 在线状态
const onLineStatus = [
  {
    value: 0,
    label: "离线",
  },
  {
    value: 1,
    label: "在线",
  },
];
const { map: onLineStatusMap, setOps: onLineStatusOps } = mapHelper.setMap(onLineStatus);

export { isOPenMap, isOPen, onLineStatusMap, onLineStatusOps };
