<template>
  <div class="iotDevice">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="导入" @click="toImport"></v-button>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <community-select
          spaceTypes="10,30"
          specifyCollectionTypes=""
          label="选择园区"
          v-model="searchParam.spaceId"
        />
        <v-input label="设备名称" v-model="searchParam.deviceName" />
        <v-select
          label="产品类型"
          clearable
          filterable
          :options="kindList"
          v-model="searchParam.productId"
        />
        <v-select
          label="在线状态"
          clearable
          filterable
          :options="onLineStatusOps"
          v-model="searchParam.status"
          @change="$refs.list.getList()"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          :text="scope.row.isOpen ? '关闭远程控制' : '开启远程控制'"
          type="text"
          @click="toOpen(scope.row)"
        />
        <v-button text="查看数据" type="text" @click="toDetail(scope.row)" />
        <v-button
          text="查看事件"
          type="text"
          @click="toDetailEvent(scope.row)"
        />
        <v-button text="查看设备密钥" type="text" @click="toKey(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="设备密钥"
      class="realNameAuditForm-addAddress"
      v-model="isdialog"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="关闭"
    >
      <v-input
        disabled
        clearable
        type="textarea"
        v-model="deviceCredentials"
        width="100%"
        :maxlength="10000"
      ></v-input>
    </v-dialog>
  </div>
</template>

<script>
import {
  getDeviceList,
  partyMemberDetailUrl,
  editPartyMemberUrl,
  deleteDeviceUrl,
  getDeviceCredentials,
  remoteControlUrl,
  getDeviceProList,
} from "./api.js";
import { isOPenMap, onLineStatusOps } from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      kindList: [],
      isdialog: false,
      searchParam: {
        deviceName: "",
        spaceId: "",
        productId: "",
        tenantId: this.$store.state.app.userInfo.tenantId,
        status: "",
      },

      tableUrl: getDeviceList,
      deviceCredentials: "",
      headers: [
        {
          prop: "deviceName",
          label: "设备名称",
        },
        {
          prop: "productName",
          label: "产品名称",
        },
        {
          prop: "deviceNum",
          label: "序列号",
        },
        {
          prop: "statusName",
          label: "状态",
        },
        {
          prop: "isOpen",
          label: "远程控制开关",
          formatter: (row, prop) => {
            return isOPenMap[row.isOpen];
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      onLineStatusOps: onLineStatusOps(),
    };
  },
  created() {
    this.getProAllListUrl();
  },
  computed: {},
  methods: {
    confirm() {},
    async getProAllListUrl() {
      let params = {
        curPage: 1,
        pageSize: 1000,
      };
      this.kindList = [];
      let res = await this.$axios.get(`${getDeviceProList}`, { params });
      if (res.code === 200) {
        if (res.data && res.data.records) {
          res.data.records.forEach((ele) => {
            let obj = {};
            obj.label = ele.name;
            obj.value = ele.id;
            this.kindList.push(obj);
          });
        }
      }
    },
    toOpen(row) {
      this.$confirm(
        row.isOpen ? "是否关闭远程控制?" : "是否开启远程控制?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          deviceNum: row.deviceNum,
        };
        this.$axios
          .post(`${remoteControlUrl(params)}?isOpen=${!row.isOpen}`)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    async getDeviceCredentials(data) {
      let params = {};
      this.proList = [];
      let res = await this.$axios.get(
        `${getDeviceCredentials({ deviceId: data })}`,
        {
          params,
        }
      );
      if (res.code === 200) {
        this.deviceCredentials = res.data;
      }
    },
    async toKey(row) {
      await this.getDeviceCredentials(row.id);
      this.isdialog = true;
    },
    toImport() {
      this.$router.push({
        name: "iotDeviceImportForm",
      });
    },
    toDetail(row) {
      this.$router.push({
        name: "iotDeviceDetail",
        query: {
          id: row.deviceNum,
        },
      });
    },
    toDetailEvent(row) {
      this.$router.push({
        name: "iotDeviceDetailEvent",
        query: {
          id: row.deviceNum,
        },
      });
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: item.id,
        };
        this.$axios.post(`${deleteDeviceUrl(params)}`).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },

    toAdd() {
      this.$router.push({
        name: "iotDeviceForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "iotDeviceForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iotDevice {
  box-sizing: border-box;
  height: 100%;
}
</style>
